<template>
  <div class="check">
    <div style="margin: 0 20px 20px 20px">
      温馨提示：本模块数据为零售出口归集跨境关单的出口商品数据，适用于办理跨境电商零售出口退免税有票业务
    </div>
    <div class="flex">
      <div class="flexBox">
        <div class="flexTitle" style="width: 250px">海关汇总申请编号</div>
        <el-input v-model="search_param.sum_no"></el-input>
      </div>
      <div class="flexBox">
        <div class="flexTitle" style="width: 250px">汇总统一编号</div>
        <el-input v-model="search_param.decl_seq_no"></el-input>
      </div>
      <div class="flexBox">
        <div class="flexTitle" style="width: 250px">暂存编号</div>
        <el-input v-model="search_param.dec_tmp_no"></el-input>
      </div>
    </div>
    <div class="flex">
      <div class="flexBox">
        <div class="flexTitle" style="width: 250px">报关单编号</div>
        <el-input v-model="search_param.entry_id"></el-input>
      </div>
      <div class="flexBox">
        <div class="flexTitle" style="width: 270px">结关日期</div>
        <el-date-picker
          v-model="search_param.op_date"
          type="datetimerange"
          range-separator="~"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          style="width: 100%"
        />
      </div>
      <div class="flexBox">
        <div class="flexTitle" style="width: 250px">状态</div>
        <el-select
          v-model="search_param.status"
          placeholder="全部状态"
          style="width: 100%"
          clearable
        >
          <el-option key="1" label="未完成" :value="1" />
          <el-option key="2" label="已汇总" :value="2" />
        </el-select>
      </div>
    </div>
    <div class="flex">
      <div
        class="flexBox"
        style="margin-right: 30px; flex: 1; margin-left: 10px"
      >
        <el-button
          v-debounce
          type="primary"
          style="margin-left: 5px"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
        <el-button v-debounce type="primary" @click="exportFile(1)"
          >导出出口销售信息</el-button
        >
        <el-button v-debounce type="primary" @click="exportFile(2)"
          >导出详情</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%"
      height="460"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange"
      @row-click="rowClick"
      @sort-change="handleSortChange"
      :header-cell-class-name="handleHeaderClass"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="状态" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.status || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="发起状态" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.summary_status || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="海关汇总申请编号"
        min-width="90px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.sum_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="汇总统一编号"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.decl_seq_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="暂存编号" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.dec_tmp_no || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="报关单编号"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.entry_id || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="汇总日期" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.summary_date || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="结关日期"
        min-width="120px"
        prop="order_by_op_date"
        sortable="custom"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.op_date || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="境内收发货人"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.ebc_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="境内收发货人社会信用代码"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.ebc_code || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="监管方式" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.regulatory_type || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="汇总结果单回执"
        min-width="140px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.guid || "暂无" }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <!-- 抽屉详情 -->
    <el-drawer
      v-model="showDrawer1"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 10px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span
              @click="
                showDrawer1 = false;
                activeName = 'first';
              "
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>出口明细详情</span>
            <span style="font-size: 13px; float: right"
              >汇总结果单回执：{{ rowDetail.guid }}</span
            >
          </span>
        </div>
        <el-divider />
        <div style="margin-bottom: 30px">
          <div style="margin-bottom: 10px">
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >海关汇总申请编号：</span
              >
              <span>{{ rowDetail.sum_no }}</span>
            </span>
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >汇总统一编号：</span
              >
              <span>{{ rowDetail.decl_seq_no }}</span>
            </span>
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >预录入编号：</span
              >
              <span>{{ rowDetail.dec_pre_no || "暂无" }}</span>
            </span>
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >电子口岸暂存编号：</span
              >
              <span>{{ rowDetail.dec_tmp_no }}</span>
            </span>
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >报关单编号：</span
              >
              <span>{{ rowDetail.entry_id }}</span>
            </span>
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >汇总日期：</span
              >
              <span>{{ rowDetail.op_date }}</span>
            </span>
          </div>
          <div style="margin-bottom: 10px">
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >关单金额：</span
              >
              <span>{{ rowDetail.amount }}</span>
            </span>
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >商品项数：</span
              >
              <span>{{ rowDetail.gcount }}</span>
            </span>
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >境内收发货人：</span
              >
              <span>{{ rowDetail.ebc_name }}</span>
            </span>
            <span style="margin-right: 20px">
              <span style="font-weight: 700; margin-right: 5px"
                >监管方式：</span
              >
              <span>{{ rowDetail.regulatory_type }}</span>
            </span>
          </div>
          <div style="margin-bottom: 10px">
            <span style="margin-right: 20px">
              <span
                style="font-weight: 700; margin-right: 5px; line-height: 60px"
                >备注：</span
              >
              <span>
                <el-input
                  v-model="rowDetail.note"
                  style="width: 350px"
                  :rows="2"
                  type="textarea"
                  @blur="noteChange"
                />
              </span>
            </span>
          </div>
        </div>
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
          style="margin-top: -15px"
        >
          <el-tab-pane label="商品汇总" name="first">
            <el-table
              :data="tableData2"
              style="width: 100%; height: 450px"
              :border="1"
              @sort-change="handleSortChange"
              @selection-change="handleSelectionChange"
              :header-cell-class-name="handleHeaderClass"
            >
              <el-table-column min-width="100px" show-overflow-tooltip>
                <template v-slot:default="scope">
                  <div>{{ scope.row.gcode || "暂无" }}</div>
                </template>
                <template v-slot:header>
                  <div style="display: flex; align-items: center">
                    <div>HS编码</div>
                    <div class="tableHeader">
                      <el-popover
                        placement="bottom-end"
                        :width="300"
                        trigger="click"
                        @hide="clearValue"
                      >
                        <template #reference>
                          <el-icon color="#1890ff" size="15"
                            ><Search
                          /></el-icon>
                        </template>
                        <div style="display: flex">
                          <el-input
                            v-model="search_param2.gcode"
                            clearable
                          ></el-input>
                          <el-button
                            v-debounce
                            type="primary"
                            @click="getList2"
                            style="margin-left: 10px"
                            >搜索</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100px" show-overflow-tooltip>
                <template v-slot:default="scope">
                  <div>{{ scope.row.gname || "暂无" }}</div>
                </template>
                <template v-slot:header>
                  <div style="display: flex; align-items: center">
                    <div>货物名称</div>
                    <div class="tableHeader">
                      <el-popover
                        placement="bottom-end"
                        :width="300"
                        trigger="click"
                        @hide="clearValue"
                      >
                        <template #reference>
                          <el-icon color="#1890ff" size="15"
                            ><Search
                          /></el-icon>
                        </template>
                        <div style="display: flex">
                          <el-input
                            v-model="search_param2.gname"
                            clearable
                          ></el-input>
                          <el-button
                            v-debounce
                            type="primary"
                            @click="getList2"
                            style="margin-left: 10px"
                            >搜索</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="成交单位"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.unit || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="单价"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.price || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="币制"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <el-select
                    v-model="scope.row.currency"
                    disabled
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in CurrencyCodeOption"
                      :key="item.currency_code"
                      :label="item.currency_Chinese_name"
                      :value="item.currency_code"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="成交数量"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.qty || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="金额"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.total_price || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="100px" fixed="right">
                <template v-slot:default="scope">
                  <div style="cursor: pointer">
                    <el-text
                      type="primary"
                      @click="toDetail(scope.row.eg_id_list)"
                      size="small"
                      style="margin-right: 10px"
                    >
                      清单详情</el-text
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="demo-pagination-block">
              <el-pagination
                v-model:current-page="currentPage2"
                :page-sizes="[10, 30, 50, 100, 500]"
                v-model:page-size="pageSize2"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount2"
                @current-change="handleCurrentChange2"
                @size-change="handleSizeChange2"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="清单详情" name="second" disabled>
            <el-table
              :data="tableData3"
              style="width: 100%; height: 450px"
              :border="1"
              @sort-change="handleSortChange2"
              @selection-change="handleSelectionChange"
              :header-cell-class-name="handleHeaderClass2"
            >
              <el-table-column type="selection" width="55" />
              <el-table-column min-width="100px" show-overflow-tooltip>
                <template v-slot:default="scope">
                  <div>{{ scope.row.invt_no || "暂无" }}</div>
                </template>
                <template v-slot:header>
                  <div style="display: flex; align-items: center">
                    <div>海关清单编号</div>
                    <div class="tableHeader">
                      <el-popover
                        placement="bottom-end"
                        :width="300"
                        trigger="click"
                        @hide="clearValue2"
                      >
                        <template #reference>
                          <el-icon color="#1890ff" size="15"
                            ><Search
                          /></el-icon>
                        </template>
                        <div style="display: flex">
                          <el-input
                            v-model="search_param3.invt_no"
                            clearable
                          ></el-input>
                          <el-button
                            v-debounce
                            type="primary"
                            @click="getList3"
                            style="margin-left: 10px"
                            >搜索</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="清单商品项号"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.gcount || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column min-width="100px" show-overflow-tooltip>
                <template v-slot:default="scope">
                  <div>{{ scope.row.gcode || "暂无" }}</div>
                </template>
                <template v-slot:header>
                  <div style="display: flex; align-items: center">
                    <div>HS编码</div>
                    <div class="tableHeader">
                      <el-popover
                        placement="bottom-end"
                        :width="300"
                        trigger="click"
                        @hide="clearValue2"
                      >
                        <template #reference>
                          <el-icon color="#1890ff" size="15"
                            ><Search
                          /></el-icon>
                        </template>
                        <div style="display: flex">
                          <el-input
                            v-model="search_param3.gcode"
                            clearable
                          ></el-input>
                          <el-button
                            v-debounce
                            type="primary"
                            @click="getList3"
                            style="margin-left: 10px"
                            >搜索</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100px" show-overflow-tooltip>
                <template v-slot:default="scope">
                  <div>{{ scope.row.gname || "暂无" }}</div>
                </template>
                <template v-slot:header>
                  <div style="display: flex; align-items: center">
                    <div>货物名称</div>
                    <div class="tableHeader">
                      <el-popover
                        placement="bottom-end"
                        :width="300"
                        trigger="click"
                        @hide="clearValue2"
                      >
                        <template #reference>
                          <el-icon color="#1890ff" size="15"
                            ><Search
                          /></el-icon>
                        </template>
                        <div style="display: flex">
                          <el-input
                            v-model="search_param3.gname"
                            clearable
                          ></el-input>
                          <el-button
                            v-debounce
                            type="primary"
                            @click="getList3"
                            style="margin-left: 10px"
                            >搜索</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="成交单位"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.unit || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="单价"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.price || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="币制"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <el-select
                    v-model="scope.row.currency"
                    disabled
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in CurrencyCodeOption"
                      :key="item.currency_code"
                      :label="item.currency_Chinese_name"
                      :value="item.currency_code"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="成交数量"
                min-width="100px"
                prop="order_by_qty"
                sortable="custom"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.qty || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="金额"
                min-width="100px"
                prop="order_by_total_price"
                sortable="custom"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.total_price || "暂无" }}</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="demo-pagination-block">
              <el-pagination
                v-model:current-page="currentPage3"
                :page-sizes="[10, 30, 50, 100, 500]"
                v-model:page-size="pageSize3"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount3"
                @current-change="handleCurrentChange3"
                @size-change="handleSizeChange3"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="汇总结果单" name="third">
            <el-table
              :data="tableData4"
              style="width: 100%; height: 450px"
              :border="1"
              @sort-change="handleSortChange3"
              @selection-change="handleSelectionChange"
              :header-cell-class-name="handleHeaderClass3"
            >
              <el-table-column type="selection" width="55" />
              <el-table-column
                label="接收时间"
                min-width="100px"
                prop="order_by_op_date"
                sortable="custom"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div>{{ scope.row.op_date || "暂无" }}</div>
                </template>
              </el-table-column>
              <el-table-column min-width="100px" show-overflow-tooltip>
                <template v-slot:default="scope">
                  <div>{{ scope.row.guid || "暂无" }}</div>
                </template>
                <template v-slot:header>
                  <div style="display: flex; align-items: center">
                    <div>回执编号</div>
                    <div class="tableHeader">
                      <el-popover
                        placement="bottom-end"
                        :width="300"
                        trigger="click"
                        @hide="clearValue3"
                      >
                        <template #reference>
                          <el-icon color="#1890ff" size="15"
                            ><Search
                          /></el-icon>
                        </template>
                        <div style="display: flex">
                          <el-input
                            v-model="search_param4.guid"
                            clearable
                          ></el-input>
                          <el-button
                            v-debounce
                            type="primary"
                            @click="getList4"
                            style="margin-left: 10px"
                            >搜索</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100px" show-overflow-tooltip>
                <template v-slot:default="scope">
                  <div>{{ scope.row.dec_status || "暂无" }}</div>
                </template>
                <template v-slot:header>
                  <div style="display: flex; align-items: center">
                    <div>状态</div>
                    <div class="tableHeader">
                      <el-popover
                        placement="bottom-end"
                        :width="300"
                        trigger="click"
                        @hide="clearValue3"
                      >
                        <template #reference>
                          <el-icon color="#1890ff" size="15"
                            ><Search
                          /></el-icon>
                        </template>
                        <div style="display: flex">
                          <el-select
                            v-model="search_param4.dec_stauts"
                            placeholder="全部状态"
                            style="width: 100%"
                            :teleported="false"
                            clearable
                          >
                            <el-option key="26" label="放行" :value="26" />
                            <el-option key="50" label="初始生成" :value="50" />
                            <el-option key="51" label="报QP成功" :value="51" />
                            <el-option key="899" label="结关" :value="899" />
                          </el-select>
                          <el-button
                            v-debounce
                            type="primary"
                            @click="getList4"
                            style="margin-left: 10px"
                            >搜索</el-button
                          >
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="操作提示"
                min-width="100px"
                show-overflow-tooltip
              >
                <template v-slot:default="scope">
                  <div
                    @click="summary(scope.row.srb_id)"
                    v-if="scope.row.enable_summary"
                    style="text-decoration: underline; cursor: pointer"
                  >
                    {{ scope.row.summary_ties }}
                  </div>
                  <div v-else>{{ scope.row.summary_ties }}</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="demo-pagination-block">
              <el-pagination
                v-model:current-page="currentPage4"
                :page-sizes="[10, 30, 50, 100, 500]"
                v-model:page-size="pageSize4"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount4"
                @current-change="handleCurrentChange4"
                @size-change="handleSizeChange4"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  listResultBill,
  getResultBill,
  listResultBillGoods,
  listResultBillElist,
  listSummaryResultBill,
  summaryGoods,
  updateNote,
  exportResultBillList,
  exportResultBillDetail,
} from "@/api/import/taxRefund.js";
import { getCurrency } from "@/api/public/index";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import { Search } from "@element-plus/icons-vue";
import fileDownload from "js-file-download";

//查询参数对象
const search_param = reactive({
  sum_no: "", //海关汇总申请编号
  decl_seq_no: "", //汇总统一编号
  dec_tmp_no: "", //预录入编号
  entry_id: "", //报关单编号
  op_date: [],
  order_by_op_date: "", //按汇总日期排序
  status: "", // 1未完成 2已汇总
  page: 1,
  size: 10,
});

//查询参数对象
const search_param2 = reactive({
  srb_id: "",
  gcode: "",
  gname: "",
  page: 1,
  size: 10,
});

const search_param3 = reactive({
  eg_id_list: [],
  gcode: "",
  gname: "",
  invt_no: "",
  order_by_qty: 0,
  order_by_total_price: 0,
  page: 1,
  size: 10,
});

const search_param4 = reactive({
  decl_seq_no: "",
  guid: "",
  order_by_op_date: "",
  dec_stauts: "",
  page: 1,
  size: 10,
});

//隐藏搜索框
const clearValue = () => {
  Object.assign(search_param2, {
    gcode: "",
    gname: "",
  });
};

//隐藏搜索框
const clearValue2 = () => {
  Object.assign(search_param3, {
    gcode: "",
    gname: "",
    invt_no: "",
  });
};

//隐藏搜索框
const clearValue3 = () => {
  Object.assign(search_param4, {
    guid: "",
    dec_stauts: "",
  });
};

// 重置功能
const reset = () => {
  search_param.sum_no = "";
  search_param.decl_seq_no = "";
  search_param.dec_tmp_no = "";
  search_param.entry_id = "";
  search_param.order_by_op_date = "";
  search_param.status = "";
  search_param.op_date = [];
};

const noteChange = async () => {
  let res = await updateNote({
    srb_id: srbId.value,
    note: rowDetail.value.note,
  });
  if (res.code == 200) {
    getDetail();
  }
};

const CurrencyCodeOption = ref([]);
const getCurrencyOption = async () => {
  let res = await getCurrency();
  if (res.code == 200) CurrencyCodeOption.value = res.data;
};

const exportFile = async (type) => {
  if (!multipleSelection.value.length) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择需要导出的数据",
      type: "error",
    });
  }
  let sendFn = null;
  if (type == 1) {
    sendFn = exportResultBillList;
  }
  if (type == 2) {
    sendFn = exportResultBillDetail;
  }
  let res = await sendFn({
    srb_list: multipleSelection.value.map((item) => item.srb_id),
  });
  let filename = res.headers["content-disposition"];
  if (filename) {
    const index = filename.indexOf("filename=");
    if (index >= 0) filename = decodeURI(filename.substr(index + 9));
  }
  fileDownload(res.data, filename, "utf-8");
  ElMessage({
    grouping: true,
    showClose: true,
    message: "导出成功",
    type: "success",
  });
};

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 表格数据
let tableData = ref([]);
let multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
};
const getList = ({
  cP = currentPage.value,
  size = pageSize.value,
  tips = false,
}) => {
  currentPage.value = cP;
  pageSize.value = size;
  listResultBill({
    sum_no: search_param.sum_no,
    decl_seq_no: search_param.decl_seq_no,
    dec_tmp_no: search_param.dec_tmp_no,
    entry_id: search_param.entry_id,
    op_date: search_param.op_date,
    order_by_op_date: search_param.order_by_op_date,
    status: search_param.status == "" ? null : search_param.status,
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list;
      totalCount.value = res.data.count;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
  });
};

const showDrawer1 = ref(false);

const activeName = ref("first");
const handleClick = (tab) => {
  if (tab.props.name == "first") getList2({ cP: 1, size: 10, tips: true });
  if (tab.props.name == "second") getList3({ cP: 1, size: 10, tips: true });
  if (tab.props.name == "third") getList4({ cP: 1, size: 10, tips: true });
};

//分页逻辑
const tableData2 = ref([]);
const currentPage2 = ref(1);
const pageSize2 = ref(10);
const totalCount2 = ref(0);
const handleCurrentChange2 = (val) => {
  currentPage2.value = val;
  getList2({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};
const handleSizeChange2 = (val) => {
  pageSize2.value = val;
  getList2({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};
// 获取
const getList2 = async ({
  cP = currentPage2.value,
  size = pageSize2.value,
  tips = false,
}) => {
  let res = await listResultBillGoods({
    srb_id: srbId.value || "",
    gcode: search_param2.gcode || "",
    gname: search_param2.gname || "",
    page: cP,
    size: size,
  });
  if (res.code == 200) {
    totalCount2.value = res.data.count;
    tableData2.value = res.data.list;
    if (!tips)
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
  }
};

//分页逻辑
const tableData3 = ref([]);
const currentPage3 = ref(1);
const pageSize3 = ref(10);
const totalCount3 = ref(0);
const handleCurrentChange3 = (val) => {
  currentPage3.value = val;
  getList3({
    cP: currentPage3.value,
    size: pageSize3.value,
    tips: true,
  });
};
const handleSizeChange3 = (val) => {
  pageSize3.value = val;
  getList3({
    cP: currentPage3.value,
    size: pageSize3.value,
    tips: true,
  });
};

const goodsElistId = ref("");
// 获取
const getList3 = async ({
  cP = currentPage3.value,
  size = pageSize3.value,
  tips = false,
}) => {
  let res = await listResultBillElist({
    eg_id_list: goodsElistId.value || [],
    gcode: search_param3.gcode || "",
    gname: search_param3.gname || "",
    invt_no: search_param3.invt_no || "",
    order_by_qty: search_param3.order_by_qty || "",
    order_by_total_price: search_param3.order_by_total_price || "",
    page: cP,
    size: size,
  });
  if (res.code == 200) {
    totalCount3.value = res.data.count;
    tableData3.value = res.data.list;
    if (!tips)
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
  }
};

//分页逻辑
const tableData4 = ref([]);
const currentPage4 = ref(1);
const pageSize4 = ref(10);
const totalCount4 = ref(0);
const handleCurrentChange4 = (val) => {
  currentPage4.value = val;
  getList4({
    cP: currentPage4.value,
    size: pageSize4.value,
    tips: true,
  });
};
const handleSizeChange4 = (val) => {
  pageSize4.value = val;
  getList4({
    cP: currentPage4.value,
    size: pageSize4.value,
    tips: true,
  });
};

// 获取
const getList4 = async ({
  cP = currentPage4.value,
  size = pageSize4.value,
  tips = false,
}) => {
  let res = await listSummaryResultBill({
    guid: search_param4.guid || "",
    decl_seq_no: rowDetail.value.decl_seq_no || "",
    dec_stauts: search_param4.dec_stauts || "",
    order_by_op_date: search_param4.order_by_op_date || "",
    page: cP,
    size: size,
  });
  if (res.code == 200) {
    totalCount4.value = res.data.count;
    tableData4.value = res.data.list;
    if (!tips)
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
  }
};

const summary = async (id) => {
  let res = await summaryGoods({
    srb_id: id,
  });
  if (res.code == 200) {
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
    getList4({
      cP: currentPage4.value,
      size: pageSize4.value,
      tips: true,
    });
  }
};

const srbId = ref("");
const rowClick = async (item) => {
  srbId.value = item.srb_id;
  await getDetail();
  showDrawer1.value = true;
  getList2({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};

const rowDetail = ref({});
// 获取详情
const getDetail = async () => {
  let res = await getResultBill({ srb_id: srbId.value });
  if (res.code == 200) {
    rowDetail.value = res.data;
  }
};

const toDetail = async (id) => {
  goodsElistId.value = id;
  getList3({
    cP: currentPage3.value,
    size: pageSize3.value,
    tips: true,
  });
  activeName.value = "second";
};

// 拉伸表格
const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};

//排序样式
const handleHeaderClass = ({ column }) => {
  const { property } = column;
  if (sortState[property]) {
    column.order = sortState[property];
  }
};
//初始状态
const sortState = {
  order_by_op_date: null,
};
const handleSortChange = ({ prop, order }) => {
  // 复制当前的排序状态
  sortState[prop] = order;
  // 更新所有列的排序状态
  if (sortState.order_by_op_date === "ascending") {
    search_param.order_by_op_date = 1;
  } else if (sortState.order_by_op_date === "descending") {
    search_param.order_by_op_date = 2;
  } else {
    search_param.order_by_op_date = 0;
  }
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

//排序样式
const handleHeaderClass2 = ({ column }) => {
  const { property } = column;
  if (sortState2[property]) {
    column.order = sortState2[property];
  }
};
//初始状态
const sortState2 = {
  order_by_qty: null,
  order_by_total_price: null,
};
const handleSortChange2 = ({ prop, order }) => {
  // 复制当前的排序状态
  sortState2[prop] = order;
  // 更新所有列的排序状态
  if (sortState2.order_by_qty === "ascending") {
    search_param3.order_by_qty = 1;
  } else if (sortState2.order_by_qty === "descending") {
    search_param3.order_by_qty = 2;
  } else {
    search_param3.order_by_qty = 0;
  }
  if (sortState2.order_by_total_price === "ascending") {
    search_param3.order_by_total_price = 1;
  } else if (sortState2.order_by_total_price === "descending") {
    search_param3.order_by_total_price = 2;
  } else {
    search_param3.order_by_total_price = 0;
  }
  getList3({ cP: currentPage3.value, size: pageSize3.value, tips: true });
};

//排序样式
const handleHeaderClass3 = ({ column }) => {
  const { property } = column;
  if (sortState3[property]) {
    column.order = sortState3[property];
  }
};
//初始状态
const sortState3 = {
  order_by_op_date: null,
};
const handleSortChange3 = ({ prop, order }) => {
  // 复制当前的排序状态
  sortState3[prop] = order;
  // 更新所有列的排序状态arch_param3.order_by_qty = 0;
  if (sortState3.order_by_op_date === "ascending") {
    search_param4.order_by_op_date = 1;
  } else if (sortState3.order_by_op_date === "descending") {
    search_param4.order_by_op_date = 2;
  } else {
    search_param4.order_by_op_date = 0;
  }
  getList4({ cP: currentPage4.value, size: pageSize4.value, tips: true });
};

onMounted(() => {
  getCurrencyOption();
});
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputStyle {
  margin-bottom: 10px;
  margin-left: 50px;
  line-height: 30px;
  display: flex;
  text-align: right;
  flex: 1;
}
.inputStyle .span1 {
  width: 320px;
  padding-right: 15px;
  line-height: 30px;
}
.inputStyle .span2 {
  width: 100%;
}

.inputStyle .span3 {
  flex: 1;
  margin-left: 20px;
  font-size: 13px;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner) {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after) {
  border-color: #fff;
}
:deep(.el-checkbox__input.is-disabled + span.el-checkbox__label) {
  color: #606266;
}
</style>
